<template>
  <div class="container">
    <div class="position">
      <div>您当前的位置：</div>
      <div @click="to('/')">首页</div>
      <div>|</div>
      <div>礼品资讯</div>
    </div>
    <img
      style="margin: 10px 0"
      src="http://shop.shangjiu-gift.com.cn/Uploads/201901/00078_adf2dd.jpg"
      alt=""
    />
    <div class="main">
      <div class="sliderbar">
        <img
          src="http://shop.shangjiu-gift.com.cn/Uploads/site1/202007/lexon__a9441f.jpg"
          alt=""
        />
        <img
          src="http://shop.shangjiu-gift.com.cn/Uploads/site1/202007/lexon__a9441f.jpg"
          alt=""
        />
      </div>
      <div class="contentp" :style="{ height: detail ? '' : 620 + 'px' }">
        <div class="crumbs">
          <div @click="to('/')" class="el-icon-s-home"></div>
          <div>></div>
          <div>礼品资讯</div>
        </div>
        <div class="help_contain">
          <div v-if="!detail">
            <div @click="toHelpDetail" v-for="i in 3" class="help-item">
              <img
                src="http://shop.shangjiu-gift.com.cn/Uploads/site1/202110/800f0229a75c48a3b00048d098180b74.jpg"
                alt=""
              />
              <div class="help-right">
                <div class="help-title">文创好物，增添生活乐趣的匠心好物!</div>
                <div class="help-desc">
                  “文创”是近年来的消费热潮。从故宫文创、到各种跨界国潮文创……文创正以生生不息的创新力和高附加值赋能多元产业，快速占领消费者心智，成为礼品市场的宠儿。
                </div>
                <div class="help-time">
                  <div>2021-10-13</div>
                  <div>来源：深圳市尚久礼品有限公司</div>
                  <div>浏览 35537</div>
                </div>
              </div>
            </div>
            <el-pagination
              class="pagination"
              layout="prev, pager, next"
              :total="300"
            >
            </el-pagination>
          </div>
          <div class="detail" v-else>
            <div class="detail-title">文创好物，增添生活乐趣的匠心好物!</div>
            <div class="detail-time">
              2021-10-13 来源：深圳市尚久礼品有限公司 浏览 35545
            </div>
            <div class="detail-title1">
              <div class="detail-num1">01</div>
              <div>来自紫禁城的礼物，惊艳365天</div>
            </div>
            <div style="text-align: left; padding: 0 20px; margin-top: 50px">
              由宫廷文化出品、有礼有节品牌监制，两大国风联手打造的2022年单向历，用377张绝美国风插画，宫阙亭台、园林花鸟、奇珍异宝、春秋冬夏，365天每天不重样，领略紫禁城600年的生活画卷。
            </div>
            <div style="font-weight: bold; margin-top: 10px">
              有礼有节 2022宫里上新日历
            </div>
            <img
              style="margin-top: 10px"
              src="http://shop.shangjiu-gift.com.cn/Uploads/site1/202110/48f41977a3ff42f18d4007e0d5679473.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detail: false,
    };
  },
  methods: {
    to(url) {
      this.$router.push(url);
    },
    toHelpDetail() {
      this.detail = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 1200px;
  margin: 0 auto;
  .position {
    display: flex;
    font-size: 12px;
    border-bottom: 1px solid #e6212a;
    padding-bottom: 5px;
    margin-top: 20px;
    div {
      &:nth-child(1) {
        margin-right: 10px;
      }
      &:nth-child(2),
      &:nth-child(4) {
        color: #666;
        cursor: pointer;
      }
      &:nth-child(3) {
        margin: 0 10px;
      }
    }
  }
  .main {
    display: flex;
    margin-top: 20px;
    .sliderbar {
      img {
        width: 210px;
        display: block;
        margin-bottom: 10px;
      }
    }
    .contentp {
      flex: 1;
      margin-left: 30px;
      overflow-x: hidden;
      overflow-y: hidden;
      // height: 620px;
      .crumbs {
        display: flex;
        align-items: center;
        div {
          &:first-child {
            cursor: pointer;
          }
          &:nth-child(2) {
            margin: 0 5px;
            color: #c51323;
          }
          &:last-child {
            font-size: 12px;
            color: #c51323;
            font-weight: bold;
          }
        }
      }
      .help_contain {
        border-top: 3px #c51323 solid;
        background: #fff;
        height: 100%;
        padding: 10px;
        .help-item {
          display: flex;
          border-bottom: 1px dotted #c1c1c1;
          padding: 15px 0;
          cursor: pointer;
          &:first-child {
            border-top: 1px dotted #c1c1c1;
          }
          img {
            width: 166px;
            height: 105px;
          }
          .help-right {
            padding: 0 20px;
            .help-title {
              font-weight: bold;
              font-size: 16px;
            }
            .help-desc {
              margin-top: 10px;
            }
            .help-time {
              display: flex;
              color: #b0b0b0;
              font-size: 14px;
              margin-top: 5px;
              div {
                &:nth-child(2) {
                  margin: 0 50px;
                }
              }
            }
          }
        }
        .pagination {
          text-align: center;
          margin-top: 80px;
          :deep(.el-pager li) {
            border: 1px solid #cccccc;
            margin: 0 5px;
            min-width: 20px;
            color: #666;
            font-weight: normal;
          }
          :deep(.el-pager li.active) {
            color: #666;
            font-weight: normal;
            cursor: default;
            border: 1px solid #c51323;
          }
          :deep(.el-pager li:hover) {
            color: #666;
            font-weight: normal;
          }
        }
      }
      .detail {
        text-align: center;
        .detail-title {
          font-weight: bold;
          font-size: 20px;
        }
        .detail-time {
          color: #666;
          font-size: 12px;
          margin-top: 20px;
        }
        .detail-title1 {
          position: relative;
          margin-top: 50px;
          .detail-num1 {
            text-shadow: rgb(224 148 186) 0px 1.4px, rgb(224 148 186) 1px 1px,
              rgb(224 148 186) 1.4px 0px, rgb(224 148 186) 1px -1px,
              rgb(224 148 186) 0px -1.4px, rgb(224 148 186) -1px -1px,
              rgb(224 148 186) -1.4px 0px, rgb(224 148 186) -1px 1px;
            margin: 0px;
            padding: 0px;
            outline: 0px;
            max-width: 100%;
            box-sizing: border-box !important;
            word-wrap: break-word !important;
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 51px;
            line-height: 1;
            letter-spacing: 2px;
            font-size: 14px;
            font-style: italic;
          }
          div {
            &:last-child {
              position: absolute;
              font-size: 14px;
              color: #fa26a1;
              border-top: 1px solid #fa26a1;
              display: inline-block;
              left: 50%;
              transform: translateX(-50%);
              top: 10px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>